<template>
  <transition appear name="fade">
    <div class="container p-0">
      <appHeader></appHeader>
      <appAdminSidebar></appAdminSidebar>
      <div v-if="isTemplatesLoading"  class="card-container m-4 bold">
        loading...
      </div>
      <div v-if="isTemplatesLoadError" class="card-container m-4 bold">
        could not load list of templates
      </div>
      <div v-if="isTemplatesLoaded" class="card-container px-4">
        <h5 class="pt-2">Choose Email template</h5>
        <div id="templateList" class="row py-2">
          <div class="col-md-4 col-sm-12 p-2" v-for="template in templates" :key="template.id">
            <div class="image text-center p-2">
              <img v-bind:src="template.imageUrl" class="text-center" width="120" height="auto"/><br/>
              <strong class="d-block p-2">{{template.id}}</strong>
              <button class="btn btn-primary btn-sm btn-sm-block" type="button" v-on:click="chooseTemplate(template.id)" :disabled="template.id === chosenTemplateId">
                Choose
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-container text-right p-4">
        <button class="btn btn-primary btn-md btn-sm-block" type="button" v-on:click="nextStep" :disabled="!canGoToNextStep">
          Next Step
        </button>
      </div>
      <appFooter></appFooter>
    </div>
  </transition>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import { mapState } from 'vuex'
import AdminSidebar from '@/views/admin/AdminSidebar'

export default {
  components: {
    appHeader: Header,
    appFooter: Footer,
    appAdminSidebar: AdminSidebar,
  },
  data () {
    return {}
  },
  computed: {
    ...mapState({
      templates: state => state.admin.templates,
      chosenTemplateId: state => state.admin.chosenTemplateId,
      isTemplatesLoaded: state => state.admin.status.templates.initialized,
      isTemplatesLoading: state => state.admin.status.templates.refreshing,
      isTemplatesLoadError: state => state.admin.status.templates.error,
    }),
    canGoToNextStep: function () {
      return this.chosenTemplateId !== undefined
    },
  },
  methods: {
    chooseTemplate: function (templateId) {
      this.$store
          .dispatch('admin/setChosenTemplateId', {
            templateId: templateId
          })
    },
    nextStep: function () {
      this.$router.push({ name: 'AdminCardsCreate3' })
    }
  },
  created () {
    this.$store.dispatch('admin/fetchTemplates')
  },
}
</script>

<style lang="scss">
.image{
  border:1px solid #ccc;
  border-radius:8px;
}
#templateList{
  .btn{
    border-radius:4px;
  }
}
</style>
