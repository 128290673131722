var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { appear: "", name: "fade" } }, [
    _c(
      "div",
      { staticClass: "container p-0" },
      [
        _c("appHeader"),
        _c("appAdminSidebar"),
        _vm.isTemplatesLoading
          ? _c("div", { staticClass: "card-container m-4 bold" }, [
              _vm._v(" loading... ")
            ])
          : _vm._e(),
        _vm.isTemplatesLoadError
          ? _c("div", { staticClass: "card-container m-4 bold" }, [
              _vm._v(" could not load list of templates ")
            ])
          : _vm._e(),
        _vm.isTemplatesLoaded
          ? _c("div", { staticClass: "card-container px-4" }, [
              _c("h5", { staticClass: "pt-2" }, [
                _vm._v("Choose Email template")
              ]),
              _c(
                "div",
                { staticClass: "row py-2", attrs: { id: "templateList" } },
                _vm._l(_vm.templates, function(template) {
                  return _c(
                    "div",
                    { key: template.id, staticClass: "col-md-4 col-sm-12 p-2" },
                    [
                      _c("div", { staticClass: "image text-center p-2" }, [
                        _c("img", {
                          staticClass: "text-center",
                          attrs: {
                            src: template.imageUrl,
                            width: "120",
                            height: "auto"
                          }
                        }),
                        _c("br"),
                        _c("strong", { staticClass: "d-block p-2" }, [
                          _vm._v(_vm._s(template.id))
                        ]),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary btn-sm btn-sm-block",
                            attrs: {
                              type: "button",
                              disabled: template.id === _vm.chosenTemplateId
                            },
                            on: {
                              click: function($event) {
                                return _vm.chooseTemplate(template.id)
                              }
                            }
                          },
                          [_vm._v(" Choose ")]
                        )
                      ])
                    ]
                  )
                }),
                0
              )
            ])
          : _vm._e(),
        _c("div", { staticClass: "card-container text-right p-4" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary btn-md btn-sm-block",
              attrs: { type: "button", disabled: !_vm.canGoToNextStep },
              on: { click: _vm.nextStep }
            },
            [_vm._v(" Next Step ")]
          )
        ]),
        _c("appFooter")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }