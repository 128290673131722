var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex account-sidebar p-4" }, [
    _c("h4", { staticClass: "mr-4 mr-4 logo" }, [_vm._v("My account")]),
    _c(
      "div",
      {},
      [
        _c(
          "router-link",
          {
            staticClass: "nav-link p-lg-2 mr-3 m-lg-1",
            attrs: { to: { name: "AdminCardsCreate1" } }
          },
          [_vm._v(" Create new cards ")]
        )
      ],
      1
    ),
    _c(
      "div",
      {},
      [
        _c(
          "router-link",
          {
            staticClass: "nav-link p-lg-2 mr-3 m-lg-1",
            attrs: { to: { name: "AdminCardsView" } }
          },
          [
            _vm._v(" Cards "),
            _c("span", { staticClass: "sr-only" }, [_vm._v("(current)")])
          ]
        )
      ],
      1
    ),
    _c(
      "div",
      {},
      [
        _c(
          "router-link",
          {
            staticClass: "nav-link p-lg-2 mr-3 m-lg-1",
            attrs: { to: { name: "AdminSettings" } }
          },
          [_vm._v(" Settings ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }